import { Controller } from "@hotwired/stimulus"
import toastr from 'toastr';

export default class extends Controller {
  static targets = [ "resourceId" ]
  static values = {
    resourceId: Number,
    resourceName: String,
  }

  connect() {
    this.modal = $("#media-file-props")
  }

  config(event) {
    this.params = event.params
    console.log(this.params)
    if (this.params) this.generateForm(this.params)
    this.modal.modal('show')
  }

  close() {
    this.modal.modal('hide')
  }

  generateForm(params) {
    var props = params.props || []
    var body = this.modal.find(".modal-body")
    var content = ""

    content += `<form action='/attachments/update_props' method='post' data-file-id='${params.fileId}'>`

    this.params.propsFields.forEach((field) => {
      var exist_prop = props.find(item => item.key == field.key) || { value: '' }
      content += `<div class="form-group"><label class="col-sm-3 control-label">${field.title || field.key}</label>`
      // TODO
      content += `<div class="col-sm-9"> <input type="text" name="${field.key}" value="${exist_prop.value}" class="form-control" /> </div>`
      content += "</div>"
    })

    content += "</form>"
    body.html(content)
  }

  save() {
    var form = document.querySelector("#media-file-props form")
    var formData = new FormData(form)

    var props = []
    for (var [key, value] of formData.entries()) {
      if (value)
        props.push({ key: key, value: value })
    }
    var data = {
      resource_name: this.resourceNameValue,
      resource_id: this.resourceIdValue,
      file_id: form.dataset.fileId,
      props: JSON.stringify(props),
      media_type_id: this.params.mediaTypeId
    }

    fetch("/attachments/update_props", {
      method: "POST",
      headers: {
        "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
        'Accept': 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml',
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
    this.modal.modal('hide')
  }
}
