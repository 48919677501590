import { Controller } from "@hotwired/stimulus"

import SlimSelect from 'slim-select'

// Connects to data-controller="slim-autocomplete"
export default class extends Controller {

  static values = {
    url: String
  }

  connect() {
    this.select = new SlimSelect({
      select: this.element,
      settings: {
        placeholderText: 'поиск',
        searchHighlight: true,
        searchText: 'ничего не найдено',
        searchingText: 'Поиск...'
      },
      events: {
        search: (search, currentData) => {
          return this.search(search, currentData, this.urlValue)
        },

        // searchFilter: (option, search) => {
        //   console.log('ehre')
        //   return option.text.toLowerCase().indexOf(search.toLowerCase()) !== -1
        // }

        // searchFilter: (option, search) => {
        //   console.log(option)
        //   return option.text.substr(0, search.length) === search
        // }
      }
    })
  }

  search(search, currentData, url_value) {
    return new Promise((resolve, reject) => {
      if (search.length < 2) {
        return reject('Поиск должен содержать не менее 2 символов')
      }

      let url = url_value + '?' + new URLSearchParams({ phone_number: search })

      // Fetch random first and last name data
      fetch(url, {
        method: 'GET',
        headers: {},
      })
        .then((response) => response.json())
        .then((data) => {
          // Take the data and create an array of options
          // excluding any that are already selected in currentData
          const options = data
            .filter((person) => {
              return !currentData.some((optionData) => {
                return optionData.value === person
              })
            })
            .map((person) => {
              return {
                text: person,
                value: person
              }
            })

          resolve(options)
        })
    })
  }
}
