import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {

  static targets = []

  connect() {
    this.raffleId = this.data.get("raffleId")
    this.fieldData = this.data.get("fieldData")
  }

  change(e) {
    this.fetchFields(e.target.value)
  }

  async fetchFields(kind = null) {
    const response = await get(`/raffles/${this.raffleId}/raffle_validations/fields?kind=${kind}&field_data=${this.fieldData}`, {
      responseKind: 'turbo-stream'
    })
    // if (response.ok) {
    //   console.log(response.html)
    // }
  }

}
