import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select";
import { get } from '@rails/request.js'

// Connects to data-controller="tom-select"
export default class extends Controller {

  static values = {
    url: String,
    queryKey: String,
    disableSearch: Boolean,
  }

  connect() {
    var config = {
      plugins: {
        caret_position: 'caret_position',
        input_autogrow: 'input_autogrow',
        remove_button: {
          title: 'удалить'
        }
      },
      //   'caret_position',
      //   'input_autogrow',
      //   'remove_button'
      // ],
      valueField: 'value',
      closeAfterSelect: true,
      placeholder: 'поиск',

      render: {
        no_results: (data,escape) =>{
          return '<div class="no-results">Нет результата для "'+escape(data.input)+'"</div>';
        },
      },

    }

    if (!this.disableSearchValue) {
      config.load = (q, callback) => this.search(q, callback)
    }

    this.select = new TomSelect(this.element, config)
  }

  async search(q, callback) {
    const query = {}
    query[this.queryKeyValue] = q

    const response = await get(this.urlValue, {
      query: query,
      responseKind: 'json'
    })

    if (response.ok) {
      const data = await response.json
      const list = data.map((item) => { return { text: item, value: item } })
      callback(list)
    } else {
      console.log(response)
      callback()
    }
  }

}
