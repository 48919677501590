import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {

  static targets = [ 'serviceId' ]

  connect() {
  }

  serviceChanged() {
    console.log(this.serviceIdTarget.value)
  }

  async myMethod () {
    const response = await get('/my_endpoint')
    if (response.ok) {
      console.log(response)
    }
  }
}
