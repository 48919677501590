import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "form", "query", 'clearbtn', 'frame' ]

  connect() {
  }

  search(_event) {
    clearTimeout(this.timeout)

    this.queryTarget.value.length > 0 ? this.showClearBtn() : this.hideClearBtn()

    if (this.queryTarget.value.length > 2) {
      this.timeout = setTimeout(() => {
          this.formTarget.requestSubmit()
        }, 500)
    }
    else {
      this.frameTarget.innerHTML = ''
    }
  }

  clear() {
    this.queryTarget.value = ''
    this.search()
  }

  showClearBtn() {
    this.clearbtnTarget.classList.remove('hidden');
  }

  hideClearBtn() {
    this.clearbtnTarget.classList.add('hidden');
  }

  advancedSearch(event) {
    this.queryTarget.value = event.target.innerText
  }

}
