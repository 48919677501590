import { Controller } from "@hotwired/stimulus"
import { FetchRequest, get, post } from "@rails/request.js";

export default class extends Controller {

  static targets = [
    'providers',
    'providerItems',
    'providerItemFields',
    'providerId'
  ]

  connect() {
    console.log('form')
  }

  // contentType: "text/vnd.turbo-stream.html",
  async onlineType() {
    console.log('onlineType')

    const request = new FetchRequest(
      'post',
      '/compliment_providers/options_for_select',
      { responseKind: 'turbo-stream' }
    )

    const response = await request.perform()
  }

  async providerSelected(event) {
    const provider_id = event.target.value

    if (!provider_id) {
      this.providerItemsTarget.innerHTML = ''
      this.providerItemFieldsTarget.innerHTML = ''
      return
    }

    const response = await post(`/compliment_providers/${provider_id}/compliment_provider_items/options_for_select`, {
      responseKind: 'turbo-stream'
    })
  }

  async providerItemSelected(event) {
    this.provider_item_id = event.target.value

    if (!this.provider_item_id) {
      return
    }

    const request = new FetchRequest(
      'post',
      `/compliment_providers/${this.providerIdTarget.value}/compliment_provider_items/${this.provider_item_id}/faces`,
      { responseKind: 'turbo-stream' }
    )

    const response = await request.perform()
  }

  async deliveryTypeSelected(event) {
    let _type = event.target.value

    switch (_type) {
      case 'online':
        await this.onlineType()
        break;

      default:
        this.providersTarget.innerHTML = ''
        this.providerItemsTarget.innerHTML = ''
      this.providerItemFieldsTarget.innerHTML = ''
        break;
    }
  }

  async providerItemFaceSelected(event) {
    const nominal = parseInt(event.target.value)
    const group = document.querySelector('#compliment_form_nominal_group')
    const field = document.querySelector('#compliment_form_nominal_field')

    if (nominal == 0) {
      group.classList.remove('hidden')
      field.removeAttribute('disabled')
    }
    else {
      if (group) group.classList.add('hidden')
      if (field) field.setAttribute('disabled', 'disabled')
    }
  }

}
