import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js";

// Connects to data-controller="panel--mall-selector"
export default class extends Controller {
  connect() {
  }

  async handleChange(e) {
    let id = e.target.value

    const response = await post('/panel/change_current_mall', {
      body: { id },
      responseKind: "json",
    })
    if (response.ok) {
      const body = JSON.parse(await response.text)
      if (body.location) window.location.href = body.location
    }
  }
}
