import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="goods-service--locale"
export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
  }

  changeHandler(e) {
    fetch(`${this.urlValue}?locale=${e.target.value}`, {
      method: "POST",
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
      },
    }).then(response => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      location.reload()
    }).catch(error => console.error("Error:", error));
  }
}
