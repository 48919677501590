import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"

export default class extends Controller {

  static targets = [ 'dataField', 'complimentId', 'amount' ]

  connect() {
    try {
      this.data_obj = JSON.parse(this.dataFieldTarget.value)
      this.setValuesToFields()
    } catch (error) {}
  }

  dataChanged() {
    this.dataFieldTarget.value = this.generateData()
  }

  generateData() {
    let obj = {}

    if (this.complimentIdTarget.value)
      obj.compliment_id = parseInt(this.complimentIdTarget.value)

    if (this.amountTarget.value)
      obj.amount = parseInt(this.amountTarget.value)

    return Object.keys(obj).length ? JSON.stringify(obj) : null
  }

  setValuesToFields() {
    this.complimentIdTarget.value = this.data_obj.compliment_id
    this.amountTarget.value = this.data_obj.amount
  }

}
