import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"

export default class extends Controller {

  static targets = [ 'dataField', 'salePointGroupId', 'salePointId', 'amount', 'invalidMessage' ]

  connect() {
    try {
      this.data_obj = JSON.parse(this.dataFieldTarget.value)
      this.setValuesToFields()
    } catch (error) {}
  }

  dataChanged() {
    this.dataFieldTarget.value = this.generateData()
  }

  generateData() {
    let obj = { }

    if (this.salePointGroupIdTarget.value)
      obj.sale_point_group_id = parseInt(this.salePointGroupIdTarget.value)

    if (this.salePointIdTarget.value)
      obj.sale_point_id = parseInt(this.salePointIdTarget.value)

    if (this.amountTarget.value)
      obj.amount = parseInt(this.amountTarget.value)

    if (this.invalidMessageTarget.value)
      obj.invalid_message = this.invalidMessageTarget.value

    return Object.keys(obj).length ? JSON.stringify(obj) : null
  }

  setValuesToFields() {
    this.salePointGroupIdTarget.value = this.data_obj.sale_point_group_id
    this.salePointIdTarget.value = this.data_obj.sale_point_id

    this.amountTarget.value = this.data_obj.amount
    this.invalidMessageTarget.value = this.data_obj.invalid_message || null
  }

}
