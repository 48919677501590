import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {

  static targets = [ 'form', 'sendBody', 'titlePreview', 'sendButton', 'body', 'phoneNumber' ]
  static values = { url: String }

  connect() {
  }

  showModal(event) {
    this.titlePreviewTarget.innerText = this.sendBodyTarget.value
    this.bodyTarget.value = this.sendBodyTarget.value
    this.validateForm()
  }

  validateForm() {
    let phone_number = this.phoneNumberTarget.value.trim()
    let body = this.sendBodyTarget.value.trim()
    this.sendButtonTarget.disabled = !(body.length > 0 && phone_number.length > 0)
  }

  submitForm(event) {
    event.preventDefault()

    $('#send-test-push').modal('hide')

    $.ajax({
      url: this.urlValue,
      type: "POST",
      dataType: 'json',
      data: {
        body: this.sendBodyTarget.value.trim(),
        phone_number: this.phoneNumberTarget.value.trim()
      },
      success: (data, textStatus, jqXHR) => {
        $('#send-test-push-success').modal('show')
        console.log(data)
      },
      error: (error) => {
        $('#send-test-push-failure').modal('show')
      }
    })
  }

  closeModal() {
    $('#send-test-push').modal('hide')
  }

  selectRecipient(event) {
    var phone_number = event.target.innerText
    this.phoneNumberTarget.value = phone_number
    this.validateForm()
  }

  clearPhoneNumber(event) {
    this.phoneNumberTarget.value = ''
    this.validateForm()
  }
}
