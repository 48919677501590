import { Controller } from "@hotwired/stimulus"
import Cropper from 'cropperjs'
import toastr from 'toastr';

// Connects to data-controller="attachment--media-type"
export default class extends Controller {

  static targets = [
    'kind',
    'origin',
    'preview',
    'fileWidth',
    'fileHeight',
    'fileSize',
    'previewContainer',
    'imageData',
    'messageModal',
    'errorMessage',
    'fileField',
    'fileControl',
    'messageText',
    'loader',
    'attachment',
    'uploadBtn'
  ]

  static values = {
    typesUrl: String,
    url: String,
    resourceName: String,
    resourceId: Number,
    kind: String
  }

  async connect() {
    this.showLoader()
    this.isCropperImage = false
    await this.loadTypes()
  }

  async loadTypes() {
    await $.ajax({
      url: this.typesUrlValue,
      type: "GET",
      dataType: 'json',
      data: { work_with: this.resourceNameValue },
      success: (data, textStatus, jqXHR) => {
        this.kindTarget.options.add(new Option('Выберите тип', null, true))
        this.typesList = data.data

        this.typesList.forEach((item) => {
          this.kindTarget.options.add(new Option(item.name, item.id))
        })
        this.hideModalMessage()
      },
      error: (error) => {
      }
    })
  }

  mediaTypeSelected(e) {
    let id = parseInt(e.target.value)

    this.cleanupData({ keepKindField: true })

    if (id) {
      this.currentMediaType = this.typesList.find((item) => item.id == id)
      this.fileWidthTarget.innerHTML = this.currentMediaType.width
      this.fileHeightTarget.innerHTML = this.currentMediaType.height
      this.fileSizeTarget.innerHTML = `${(this.currentMediaType.size / 1048576).toFixed(1)} Мб`
      this.showFileControl()
    }
    else {
      this.cleanupData()
      this.hideFileControl()
    }
  }

  fileSelected(e) {
    if (this.cropper) {
      this.cropper.destroy()
      this.cropper = null
    }

    if (e.target.files && e.target.files[0]) {
      var file = e.target.files[0]
      this.filename = file.name

      var reader = new FileReader();

      reader.onload = (e) => {
        const originImage = e.target.result

        var img = new Image;
        img.onload = (e) => {
          this.imageWidth = img.width
          this.imageHeight = img.height

          this.originTarget.setAttribute('src', originImage)
          this.afterFileLoad(originImage)
        }
        img.src = originImage
      };

      reader.readAsDataURL(file);
      this.showAttachment()
    }
  }

  displayImageData() {
    this.imageDataTarget.innerText = `(Размер: ${this.imageWidth}x${this.imageHeight})`
  }


  async create(e) {
    e.preventDefault()
    this.showLoader()

    if (this.isCropperImage) {
      let canvas = this.cropper.getCroppedCanvas({ width: this.currentMediaType.width, height: this.currentMediaType.height })
      canvas.toBlob(async (blob) => {
        this.sendToServer(blob)
      })
    }
    else {
      var file = this.fileFieldTarget.files[0]
      this.sendToServer(file)
    }
  }

  calcAspectRatio() {
    return this.currentMediaType.width / this.currentMediaType.height
  }

  async sendToServer(blob) {
    var formData = this.generateFormData(blob);

    $.ajax(this.urlValue, {
      method: 'POST',
      data: formData,
      processData: false,
      contentType: false,
      success: (data) => {
        this.closeAppWindow()
        toastr["info"](data['result']);
        const cont = document.getElementById(this.currentMediaType.key)
        cont.querySelectorAll('.no-content').forEach((el) => el.remove())
        cont.innerHTML += data["template"]
        this.hideModalMessage()
        // location.reload()
      },
      error: (error) => {
        console.log(error)
        this.hideLoader()
        this.showBackendErrors(error.responseJSON.message)
      }
    })
  }


  generateFormData(blob) {
    var formData = new FormData();
    formData.append('file', blob, this.filename);
    formData.append('resource_name', this.resourceNameValue);
    formData.append('resource_id', this.resourceIdValue);
    formData.append('kind', this.kindValue);
    formData.append('media_type_id', this.currentMediaType.id);
    return formData;
  }

  isNeedCropper() {
    return (this.currentMediaType.width !== null && this.currentMediaType.height !== null)
  }

  isHaveRightSize() {
    return (this.imageWidth >= this.currentMediaType.width && this.imageHeight >= this.currentMediaType.height)
  }

  showBackendErrors(txt = '') {
    var str = `<div class="text-center">
      <h5>Ошибки при добавлении файла</h5>
      <p>${txt}</p>
      </div>`
    this.showModalMessage(str)
  }

  showWrongFileSize() {
    var txt = `<p class="text-center">
      Выбранный файл имеет не подходящий размер.<br />
      Минимальный размер для загрузки ${this.currentMediaType.width}x${this.currentMediaType.height}
    </p>`
    this.showModalMessage(txt)
  }

  showModalMessage(txt = '') {
    this.messageModalTarget.classList.remove('hidden')
    this.messageTextTarget.classList.remove('hidden')
    this.errorMessageTarget.innerHTML = txt
  }

  showLoader() {
    this.messageModalTarget.classList.remove('hidden')
    this.loaderTarget.classList.remove('hidden')
  }

  hideLoader() {
    this.loaderTarget.classList.add('hidden')
  }

  hideModalMessage() {
    this.messageModalTarget.classList.add('hidden')
    this.messageTextTarget.classList.add('hidden')
    this.errorMessageTarget.innerHTML = ''
    this.hideLoader()
  }

  cleanupData(options = {}) {
    this.isCropperImage = false

    if (this.cropper) {
      this.cropper.destroy()
      this.cropper = null
    }

    this.originTarget.setAttribute('src', '')
    this.previewTarget.setAttribute('src', '')

    if (!options.keepKindField)
      this.kindTarget.value = null

    this.fileFieldTarget.value = null
    this.hideUploadBtn()
    this.hideAttachment()
  }

  showAppWindow() {
    $('#media-file-upload').modal('show')
  }

  closeAppWindow() {
    $('#media-file-upload').modal('hide')
    this.hideFileControl()
    this.cleanupData()
  }

  showUploadBtn() {
    this.uploadBtnTarget.classList.remove('hidden')
  }

  hideUploadBtn() {
    this.uploadBtnTarget.classList.add('hidden')
  }

  showAttachment() {
    this.attachmentTarget.classList.remove('hidden')
  }

  hideAttachment() {
    this.attachmentTarget.classList.add('hidden')
  }

  showFileControl() {
    this.fileControlTarget.classList.remove('hidden')
  }

  hideFileControl() {
    this.fileControlTarget.classList.add('hidden')
  }

  afterFileLoad(originImage) {
    this.displayImageData()


    if (this.isNeedCropper()) {
      if (this.isHaveRightSize()) {
        this.initCropper()
        this.previewTarget.setAttribute('src', originImage)
        this.showUploadBtn()
      }
      else this.showWrongFileSize()
    }
    else {
      this.showUploadBtn()
    }
  }

  initCropper() {
    this.cropper = new Cropper(this.originTarget, {
      aspectRatio: this.calcAspectRatio(),
      viewMode: 1,
      responsive: false,
      movable: true,
      scalable: false,
      zommable: true,

      cropBoxMovable: false,
      cropBoxResizable: false,
      dragMode: 'move',

      zoom: (e) => {
        const container = this.cropper.getContainerData()
        const ratio = container.width / this.imageWidth

        if (ratio > 1) {
          if (e.detail.ratio > 1) {
            e.preventDefault();
            this.cropper.zoomTo(1.0)
          }
        }
        else {
          if (e.detail.ratio > ratio) {
            e.preventDefault();
            this.cropper.zoomTo(ratio)
          }
        }
      },

      ready: (e) => {
        let data = this.cropper.getCanvasData()
        let coef = data.width / data.naturalWidth
        let w = this.currentMediaType.width * coef
        let h = this.currentMediaType.height * coef

        this.cropper.setCropBoxData({
          left: (data.width - w) / 2,
          top: (data.height - h) / 2,
          width: w,
          height: h,
        })
      },

      crop: (event) => {
        var data = event.detail;

        var imageData = this.cropper.getImageData();

        var previewRation = this.currentMediaType.height / this.currentMediaType.width
        var previewWidth = this.previewContainerTarget.offsetWidth;
        var previewHeight = previewWidth * previewRation;

        this.previewContainerTarget.style.width = previewWidth + 'px'
        this.previewContainerTarget.style.height = previewHeight + 'px'

        var imageScaledRatio = data.width / previewWidth
        this.previewTarget.style.width = imageData.naturalWidth / imageScaledRatio + 'px';
        this.previewTarget.style.height = imageData.naturalHeight / imageScaledRatio + 'px';
        this.previewTarget.style.marginLeft = -data.x / imageScaledRatio + 'px';
        this.previewTarget.style.marginTop = -data.y / imageScaledRatio + 'px';
      }
    })

    this.isCropperImage = true
  }
}
