import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = [ "editButton" ]

  connect() {
    this.isEditing = false
    this.sortable = Sortable.create(this.element.querySelector("tbody"), {
      handle: ".sortable.sortable-handle", // Если есть колонка с handle, который будет инициировать перетаскивание
      animation: 150, // Длительность анимации в миллисекундах
      onUpdate: this.updateOrder.bind(this) // Callback-функция для обработки изменений порядка
    });
  }

  async edit() {
    this.isEditing = !this.isEditing
    await this.manipulateControl()
  }

  async toggleHandlersVisibility() {
    this.element.querySelectorAll(".sortable").forEach(el => {
      if (this.isEditing) {
        el.classList.remove("hide")
      }
      else {
        el.classList.add("hide")
      }
    })
  }


  updateOrder(event) {
    let itemId = event.item.dataset.itemId;
    let newPosition = Array.from(event.item.parentNode.children).indexOf(event.item) + 1;

    fetch(`${this.element.dataset.scope}/${itemId}/move`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ new_position: newPosition })
    }).then(response => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      // return response.json();
    }).catch(error => console.error("Error:", error));
  }

  async manipulateControl() {
    await this.toggleHandlersVisibility()

    if (this.isEditing) {
      this.editButtonTarget.text = "Сохранить"
      this.editButtonTarget.classList.remove("btn-info")
      this.editButtonTarget.classList.add("btn-default")
    }
    else {
      this.editButtonTarget.text = "Сортировать"
      this.editButtonTarget.classList.remove("btn-default")
      this.editButtonTarget.classList.add("btn-info")
    }
  }
}
